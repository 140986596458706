import { effect, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SOCIAL_ANALYTICS_SLUGS } from '@features/social-analysis/social-analytics.routes';
import { APP_SLUGS, MAIN_SLUGS } from '@routes/routes.types';
import { MessagingIntegrationsService } from '@core/services/third-party-integrations/messaging-integrations.service';

export const hasConnectedMessagingGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const integrationsS = inject(MessagingIntegrationsService);

    const hasChannel = integrationsS.hasAnyChannelBeenConnected();

    if (hasChannel) {
        return true;
    } else {
        const effRef = effect(() => {
            if (integrationsS.hasAnyChannelBeenConnected()) {
                router.navigate([state.url]);
                effRef.destroy();
            }
        });

        const route = `${APP_SLUGS.MAIN}/${MAIN_SLUGS.SOCIAL_ANALYTICS}/${SOCIAL_ANALYTICS_SLUGS.connect}`;
        router.navigate([route]);
        return false;
    }
};
