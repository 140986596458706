import { Route } from '@angular/router';
import { hasConnectedMessagingGuard } from '@core/guards/has-connected-messaging.guard';
import { subscriptionGuard } from '@features/settings/billing/guards/subscription.guard';
import { MAIN_SLUGS } from '@routes/routes.types';

export const SOCIAL_ANALYTICS_SLUGS = {
    listening: 'listening',
    users: 'users',
    connect: 'connect',
} as const;
export type SocialAnalyticsSlugs =
    (typeof SOCIAL_ANALYTICS_SLUGS)[keyof typeof SOCIAL_ANALYTICS_SLUGS];

export const SOCIAL_ANALYTICS_ROUTES: Route = {
    path: MAIN_SLUGS.SOCIAL_ANALYTICS,
    canActivate: [subscriptionGuard],
    loadComponent: () =>
        import('@features/social-analysis/social-analysis.component').then(
            m => m.SocialAnalysisComponent
        ),
    children: [
        {
            path: SOCIAL_ANALYTICS_SLUGS.connect,
            title: 'Connect messaging CTA',
            loadComponent: () =>
                import(
                    '@features/social-analysis/go-to-connect-wrapper/go-to-connect-wrapper.component'
                ).then(m => m.GoToConnectWrapperComponent),
        },
        {
            path: SOCIAL_ANALYTICS_SLUGS.listening,
            title: 'Social listening',
            loadComponent: () =>
                import(
                    '@features/social-analysis/social-listening/social-listening.component'
                ).then(m => m.SocialListeningComponent),
            canActivate: [hasConnectedMessagingGuard],
        },
        {
            path: SOCIAL_ANALYTICS_SLUGS.users,
            title: 'Social users',
            loadComponent: () =>
                import('@features/social-analysis/social-users/social-users.component').then(
                    m => m.SocialUsersComponent
                ),
            canActivate: [hasConnectedMessagingGuard],
        },

        {
            path: '',
            pathMatch: 'full',
            redirectTo: SOCIAL_ANALYTICS_SLUGS.listening,
        },
        {
            path: '**',
            redirectTo: SOCIAL_ANALYTICS_SLUGS.listening,
        },
    ],
};
